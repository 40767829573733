exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2012-09-xblig-companion-for-android-md": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Users/gglas/Devel/Webdev/gatsby/overworld-web/news/2012/09/xblig-companion-for-android.md" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2012-09-xblig-companion-for-android-md" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2015-08-support-has-ended-for-xblig-companion-md": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Users/gglas/Devel/Webdev/gatsby/overworld-web/news/2015/08/support-has-ended-for-xblig-companion.md" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2015-08-support-has-ended-for-xblig-companion-md" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2022-07-audio-engine-completed-md": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Users/gglas/Devel/Webdev/gatsby/overworld-web/news/2022/07/audio-engine-completed.md" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2022-07-audio-engine-completed-md" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2022-07-learning-roblox-dev-md": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Users/gglas/Devel/Webdev/gatsby/overworld-web/news/2022/07/learning-roblox-dev.md" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2022-07-learning-roblox-dev-md" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2022-07-modern-game-loop-md": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Users/gglas/Devel/Webdev/gatsby/overworld-web/news/2022/07/modern-game-loop.md" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2022-07-modern-game-loop-md" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2022-08-fast-thread-synchronization-in-a-cpp-game-engine-md": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Users/gglas/Devel/Webdev/gatsby/overworld-web/news/2022/08/fast-thread-synchronization-in-a-cpp-game-engine.md" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2022-08-fast-thread-synchronization-in-a-cpp-game-engine-md" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2022-08-input-engine-brief-overview-md": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Users/gglas/Devel/Webdev/gatsby/overworld-web/news/2022/08/input-engine-brief-overview.md" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-2022-08-input-engine-brief-overview-md" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-xblig-companion-js": () => import("./../../../src/pages/xblig-companion.js" /* webpackChunkName: "component---src-pages-xblig-companion-js" */)
}

